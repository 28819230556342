<template>
  <div id="importDialog">
    <el-dialog
      title="导入"
      :visible.sync="show"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      @closed="reset"
      @open="open"
      width="450px"
    >
      <el-form
        :model="actionForm"
        :rules="ruleForm"
        ref="actionForm"
        label-width="80px"
      >
        <el-form-item label="套餐" prop="priceId">
          <el-select v-model="actionForm.priceId" placeholder="请选择套餐">
            <el-option
              v-for="item of priceList"
              :key="item.priceId"
              :label="item.priceName"
              :value="item.priceId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="始终入场" prop="alwaysOpen">
          <el-radio-group v-model="actionForm.alwaysOpen">
            <el-radio label="NO">否</el-radio>
            <el-radio label="YES">是</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="租赁类型">
          <el-radio-group v-model="actionForm.leaseType">
            <el-radio :label="0">无</el-radio>
            <el-radio label="PERMANENT">免费</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="文件" prop="file" props="file">
          <el-upload
            action=""
            ref="upload"
            :limit="1"
            :on-change="handleFileChange"
            :on-remove="handleFileChange"
            :http-request="handleUpload"
            :disabled="loading"
            :auto-upload="false"
            :file-list="fileList"
            :show-file-list="true"
          >
            <el-button type="primary" round>上传 </el-button>
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="medium" @click="show = false">取 消</el-button>
        <el-button
          type="primary"
          size="medium"
          @click="confirmBtn"
          :loading="loading"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      show: false,
      loading: false,
      priceList: [],
      fileList: [],
      actionForm: {
        alwaysOpen: "YES",
        priceId: "",
        leaseType: "",
      },
      ruleForm: {
        priceId: [{ required: true, message: "请选择套餐", trigger: "change" }],
        alwaysOpen: [{ required: true, message: "请选择", trigger: "change" }],
        file: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (this.fileList.length > 0) {
                callback();
              } else {
                callback("请上传文件");
              }
            },
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    open() {
      this.parkingLotId = this.$route.params.id;
      this.getPriceList();
    },
    async getPriceList() {
      let res = await this.$http.get(`/price/simple/${this.parkingLotId}/list`);
      this.priceList = res.data;
    },
    reset() {
      this.$set(this, "actionForm", {
        priceId: "",
        alwaysOpen: "YES",
        leaseType: "",
      });
      this.fileList = [];
      this.$refs.upload.clearFiles();
      this.$refs["actionForm"].resetFields();
    },
    async handleUpload(params) {
      const fd = new FormData();
      fd.append("file", params.file);
      fd.append("parkingLotId", this.parkingLotId);
      fd.append("alwaysOpen", this.actionForm.alwaysOpen);
      fd.append("priceId", this.actionForm.priceId);
      if (this.actionForm.leaseType) {
        fd.append("leaseType", this.actionForm.leaseType);
      }

      try {
        this.loading = true;
        let res = await this.$http.post("/parking/vehicle/import", fd);
        if (res.code === 0) {
          this.$message.success("操作成功");
          this.$emit("refreshList");
          this.show = false;
        } else {
          this.$message.error(res.msg);
        }
        this.loading = false;
      } catch (err) {
        this.$message.error(err);
        this.loading = false;
      }
    },
    handleFileChange(file, fileList) {
      this.fileList = fileList;
    },
    confirmBtn() {
      this.$refs["actionForm"].validate(async (valid) => {
        if (valid) {
          this.$refs.upload.submit();
        }
      });
    },
  },
};
</script>
<style lang="scss">
#importDialog {
  .el-dialog__wrapper {
    .el-dialog {
      .el-dialog__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px;
        border: 1px solid rgba(20, 34, 57, 0.13);

        .el-dialog__title {
          font-size: 14px;
          color: #142239;
          position: relative;
          z-index: 2;
          font-weight: bold;

          &::after {
            content: "";
            display: inline-block;
            width: 100%;
            height: 3px;
            background-color: $main-color;
            position: absolute;
            left: 0;
            bottom: 4px;
            z-index: -1;
          }
        }

        .el-dialog__headerbtn {
          position: initial;

          i {
            color: black;
          }
        }
      }

      .el-dialog__body {
        padding: 15px;

        .el-select,
        .el-date-editor {
          width: 100%;
        }

        .black-white-list {
          li {
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;

            &:last-child {
              margin-bottom: 0;
            }

            .el-date-editor {
              width: 170px;

              .el-input__inner {
                padding-right: 10px;
              }
            }
          }
        }
      }

      .el-dialog__footer {
        text-align: center;

        .dialog-footer {
          .el-button--default {
            border-color: $main-color;
            color: $main-color;
          }
        }
      }
    }
  }
}
</style>
